// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blocked-tsx": () => import("./../../../src/pages/blocked.tsx" /* webpackChunkName: "component---src-pages-blocked-tsx" */),
  "component---src-pages-certification-developpeur-securite-tsx": () => import("./../../../src/pages/certification-developpeur-securite.tsx" /* webpackChunkName: "component---src-pages-certification-developpeur-securite-tsx" */),
  "component---src-pages-certification-developpeur-web-and-solutions-tsx": () => import("./../../../src/pages/certification-developpeur-web-and-solutions.tsx" /* webpackChunkName: "component---src-pages-certification-developpeur-web-and-solutions-tsx" */),
  "component---src-pages-certification-dveloppeur-data-tsx": () => import("./../../../src/pages/certification-dveloppeur-data.tsx" /* webpackChunkName: "component---src-pages-certification-dveloppeur-data-tsx" */),
  "component---src-pages-certification-tsx": () => import("./../../../src/pages/certification.tsx" /* webpackChunkName: "component---src-pages-certification-tsx" */),
  "component---src-pages-challenges-tsx": () => import("./../../../src/pages/challenges.tsx" /* webpackChunkName: "component---src-pages-challenges-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-email-sign-up-tsx": () => import("./../../../src/pages/email-sign-up.tsx" /* webpackChunkName: "component---src-pages-email-sign-up-tsx" */),
  "component---src-pages-get-certification-tsx": () => import("./../../../src/pages/get-certification.tsx" /* webpackChunkName: "component---src-pages-get-certification-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-supporters-tsx": () => import("./../../../src/pages/supporters.tsx" /* webpackChunkName: "component---src-pages-supporters-tsx" */),
  "component---src-pages-unsubscribed-tsx": () => import("./../../../src/pages/unsubscribed.tsx" /* webpackChunkName: "component---src-pages-unsubscribed-tsx" */),
  "component---src-pages-update-email-tsx": () => import("./../../../src/pages/update-email.tsx" /* webpackChunkName: "component---src-pages-update-email-tsx" */),
  "component---src-pages-update-stripe-card-tsx": () => import("./../../../src/pages/update-stripe-card.tsx" /* webpackChunkName: "component---src-pages-update-stripe-card-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */),
  "component---src-templates-challenges-classic-show-tsx": () => import("./../../../src/templates/Challenges/classic/show.tsx" /* webpackChunkName: "component---src-templates-challenges-classic-show-tsx" */),
  "component---src-templates-challenges-codeally-show-tsx": () => import("./../../../src/templates/Challenges/codeally/show.tsx" /* webpackChunkName: "component---src-templates-challenges-codeally-show-tsx" */),
  "component---src-templates-challenges-dialogue-show-tsx": () => import("./../../../src/templates/Challenges/dialogue/show.tsx" /* webpackChunkName: "component---src-templates-challenges-dialogue-show-tsx" */),
  "component---src-templates-challenges-exam-show-tsx": () => import("./../../../src/templates/Challenges/exam/show.tsx" /* webpackChunkName: "component---src-templates-challenges-exam-show-tsx" */),
  "component---src-templates-challenges-fill-in-the-blank-show-tsx": () => import("./../../../src/templates/Challenges/fill-in-the-blank/show.tsx" /* webpackChunkName: "component---src-templates-challenges-fill-in-the-blank-show-tsx" */),
  "component---src-templates-challenges-ms-trophy-show-tsx": () => import("./../../../src/templates/Challenges/ms-trophy/show.tsx" /* webpackChunkName: "component---src-templates-challenges-ms-trophy-show-tsx" */),
  "component---src-templates-challenges-odin-show-tsx": () => import("./../../../src/templates/Challenges/odin/show.tsx" /* webpackChunkName: "component---src-templates-challenges-odin-show-tsx" */),
  "component---src-templates-challenges-projects-backend-show-tsx": () => import("./../../../src/templates/Challenges/projects/backend/show.tsx" /* webpackChunkName: "component---src-templates-challenges-projects-backend-show-tsx" */),
  "component---src-templates-challenges-projects-frontend-show-tsx": () => import("./../../../src/templates/Challenges/projects/frontend/show.tsx" /* webpackChunkName: "component---src-templates-challenges-projects-frontend-show-tsx" */),
  "component---src-templates-challenges-video-show-tsx": () => import("./../../../src/templates/Challenges/video/show.tsx" /* webpackChunkName: "component---src-templates-challenges-video-show-tsx" */),
  "component---src-templates-introduction-intro-tsx": () => import("./../../../src/templates/Introduction/intro.tsx" /* webpackChunkName: "component---src-templates-introduction-intro-tsx" */),
  "component---src-templates-introduction-super-block-intro-tsx": () => import("./../../../src/templates/Introduction/super-block-intro.tsx" /* webpackChunkName: "component---src-templates-introduction-super-block-intro-tsx" */)
}

