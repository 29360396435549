import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import notFoundLogo from '../../assets/images/freeCodeCamp-404.svg';
import { randomQuote } from '../../utils/get-words';
import { Spacer, Link } from '../helpers';

import img from './404.png'

import './404.css';

const FourOhFour = (_props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const quote = randomQuote();

  return (
    <>

    <section class="notfound-new">
        <main>
           <div>
             <h1>Page Not Found</h1>
             <p>Oops! It seems that this page has gotten lost in the design maze. Return to creation!</p>
             <button>
                <a href="/">Go Back To Home!</a>
             </button>
           </div>
           <div>
            <img src={img} alt="" />
           </div>
        </main>
    </section>
    
    </>
  );
};

export default FourOhFour;
