import {
  faCheckSquare,
  faSquare,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { radioLocation } from '../../../../config/env.json';
import { openSignoutModal } from '../../../redux/actions';
import { updateMyTheme } from '../../../redux/settings/actions';
import { Link } from '../../helpers';
import { type ThemeProps, Themes } from '../../settings/theme';
import { User } from '../../../redux/prop-types';
import SupporterBadge from '../../../assets/icons/supporter-badge';

export interface NavLinksProps extends Pick<ThemeProps, 'toggleNightMode'> {
  displayMenu: boolean;
  showMenu: () => void;
  hideMenu: () => void;
  user?: User;
  menuButtonRef: React.RefObject<HTMLButtonElement>;
  openSignoutModal: () => void;
}

const mapDispatchToProps = {
  toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
  openSignoutModal
};

interface DonateButtonProps {
  isUserDonating: boolean | undefined;
  handleMenuKeyDown: (event: React.KeyboardEvent<HTMLAnchorElement>) => void;
}

const DonateButton = ({
  isUserDonating,
  handleMenuKeyDown
}: DonateButtonProps) => {
  const { t } = useTranslation();
  return (
    <li key={isUserDonating ? 'supporter' : 'donate'}>
      <Link
        className={`nav-link nav-link-flex nav-link-header ${
          isUserDonating && 'nav-link-supporter'
        }`}
        onKeyDown={handleMenuKeyDown}
        sameTab={false}
        to={isUserDonating ? '/supporters' : '/donate'}
        data-test-label={
          isUserDonating ? 'dropdown-support-button' : 'dropdown-donate-button'
        }
      >
        {isUserDonating ? (
          <>
            {t('buttons.supporters')}
            <SupporterBadge />
          </>
        ) : (
          <>{t('buttons.donate')}</>
        )}
      </Link>
    </li>
  );
};

const toggleTheme = (
  currentTheme = Themes.Default,
  toggleNightMode: typeof updateMyTheme
) => {
  toggleNightMode(
    currentTheme === Themes.Night ? Themes.Default : Themes.Night
  );
};

function NavLinks({
  menuButtonRef,
  openSignoutModal,
  hideMenu,
  displayMenu,
  toggleNightMode,
  user
}: NavLinksProps) {
  const { t } = useTranslation();
  const {
    isDonating: isUserDonating,
    username: currentUserName,
    theme: currentUserTheme
  } = user || {};

  // the accessibility tree just needs a little more time to pick up the change.
  // This function allows us to set aria-expanded to false and then delay just a bit before setting focus on the button
  const closeAndFocus = () => {
    menuButtonRef.current?.classList.add('force-show');
    hideMenu();
    setTimeout(() => {
      menuButtonRef.current?.focus();
      menuButtonRef.current?.classList.remove('force-show');
    }, 100);
  };

  const handleMenuKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      closeAndFocus();
    }
  };

  const handleSignOutKeys = (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    const DoKeyPress = new Map<string, { select: () => void }>([
      [
        'Escape',
        {
          select: () => {
            event.preventDefault();
            closeAndFocus();
          }
        }
      ],
      [
        'Tab',
        {
          select: () => {
            const camperPressedTheShiftKey = event.shiftKey;
            if (!camperPressedTheShiftKey) {
              hideMenu();
            }
          }
        }
      ]
    ]);
    DoKeyPress.get(event.key)?.select();
  };

  const handleSignOutClick = (): void => {
    hideMenu();
    openSignoutModal();
  };

  return (
   <>
     
      {currentUserName && (
        <span className='signout-new' key='sign-out'>
          <a 
            
            style={{cursor:"pointer"}}
            className='sign-out-button-new'
            data-value='sign-out-button'
            onClick={handleSignOutClick}
            onKeyDown={handleSignOutKeys}
          >
            {t('buttons.sign-out')}
          </a>
        </span>
      )}
    </>
  );
}

NavLinks.displayName = 'NavLinks';

export default connect(null, mapDispatchToProps)(withTranslation()(NavLinks));
