import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col } from '@freecodecamp/ui';
import logo from './Web4JobsLogo.png'
import location from './location.svg'
import mail from './mail.svg'
import phone from './phone.svg'
import appleStoreBadge from '../../assets/images/footer-ads/apple-store-badge.svg';
import googlePlayBadge from '../../assets/images/footer-ads/google-play-badge.svg';
import { Spacer, Link } from '../helpers';
import './footer.css';

function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
<footer className="site-footer">
  <div className="footer-top">
  <h6 className="logo-container">
        <img src={logo} className="logo" alt="logo" />
      </h6>
    <div className="footer-desc-col">

      <p className="footer-description">
        Web4jobs, your partner for the professional future. We offer specialized training programs, exclusive job opportunities, and personalized support to boost your career in IT. Join us to develop your skills and reach new professional heights.
      </p>
    </div>
    <div className="footer-contact-col">
      <h6 className="footer-title">Contacts</h6>
      <p className="footer-contact"><img src={location} alt="" /> 24, Rue 19, Missimi - Hay Hassani Casablanca - 20300</p>
      <p className="footer-contact"><img src={mail} alt="" />web4jobs@yool.education</p>
      <p className="footer-contact"><img src={phone} alt="" />+212645393690</p>
    </div>
  </div>
  <div className="footer-bottom">
    <p className="footer-copyright">
      © 2024 <span className="footer-brand">Web4jobs</span>
    </p>
  </div>
</footer>



  );
}

Footer.displayName = 'Footer';
export default Footer;
