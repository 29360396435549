import React from 'react';
import logoImage from './Web4JobsLogo.png'

function FreeCodeCampLogo(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <img src={logoImage} alt="logo" className="header-logo"  />
  );
}

FreeCodeCampLogo.displayName = 'FreeCodeCampLogo';

export default FreeCodeCampLogo;
