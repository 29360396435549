import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import envData from "../../../../config/env.json";
import { isSignedInSelector } from "../../../redux/selectors";
import callGA from "../../../analytics/call-ga";

const { apiLocation, homeLocation } = envData;

const mapStateToProps = createSelector(isSignedInSelector, (isSignedIn) => ({
  isSignedIn,
}));

interface LoginProps {
  block?: boolean;
  children?: ReactNode;
  "data-test-label"?: string;
  isSignedIn?: boolean;
}

const Login = ({
  block,
  children,
  "data-test-label": dataTestLabel,
  isSignedIn,
}: LoginProps): JSX.Element => {
  const { t } = useTranslation();

  const href = isSignedIn ? `${homeLocation}/` : `${apiLocation}/signin`;
  return <>
      <a
      className='login-button-new-home'
      data-test-label={dataTestLabel}
      data-playwright-test-label='header-sign-in-button'
      href={href}
      onClick={() => {
        callGA({
          event: 'sign_in'
        });
      }}
    >
      {/* <span className='login-button-new'>
        {/* <FontAwesomeIcon icon={faRightToBracket} /> 
        <span className='sr-only'> {t('buttons.sign-in')}</span>
      </span> */}
      <span className=''>{children || t('buttons.sign-in')}</span>
    </a>

    <style>
        {` 
    .login-button-new-home{

          padding: 10px 30px ;
          gap: 10px;
          border-radius: 10px;
          color:white;
          background: #432B84;
          text-decoration:none;
          font-size:16px;
    }
    .login-button-new-home:hover{
          padding: 10px 30px ;
          gap: 10px;
          border-radius: 10px;
          color:white;
          background: #432B84;
          text-decoration:none;
    }
    
    
  @media (max-width: 980px) {
   .login-button-new-home{
          padding:0 ;
          gap:0;
          border-radius: 0;
          color:black;
          background:none;
          text-decoration:none;
          font-size:12px;
    }
    .login-button-new-home:hover{

          padding:0 ;
          gap:0;
          border-radius: 0;
          color:black;
          background:none;
          text-decoration:none;
    }
  }
    `}
      </style>
  </>;
};

Login.displayName = "Login";

export default connect(mapStateToProps)(Login);
